import React, { Component } from 'react';
import "./contact.scss"
import i18next from 'i18next';
import { Request } from '../../auth/request';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {
	className?: string;
	id?: string;
	language: string;
	history?: any;
}

export default class ContactPage extends Component<IProps, any> {

	state: { name: string, nameError: string, email: string, emailError: string, subject: string, subjectError: string, message: string, messageError: string, postMessage: string, postStatus: string, recaptchaToken: string, recaptchaTokenError: string } = {
		name: "",
		nameError: "",
		email: "",
		emailError: "",
		subject: "",
		subjectError: "",
		message: "",
		messageError: "",
		recaptchaToken: "",
		recaptchaTokenError: "",
		postMessage: "",
		postStatus: "",
	};

	change = (name:string) => async (e:any) => {
	  // we're handling sending an email here
   let message = `${name} field cannot be empty`;

   // if this is the email field and it's not empty.
	  if(name === "email") {
	    // test to see if the regex has a match for email field.
     // so, if this email is not valid.
	    if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(e.target.value)) {
	      message = "Please input a valid email";
		    return this.setState({[name]: e.target.value, [name+"Error"]: message});
     }
   }

	  // then update the state
	  this.setState({[name]: e.target.value, [name+"Error"]: !e.target.value ? message : ""})
 };

	submitForm = (e:any) => {
			// check for errors
			if(!this.state.name || !this.state.email || !this.state.message || !this.state.subject || !this.state.recaptchaToken) {
				return this.setState({
					nameError: this.state.name ? "" : (this.state.nameError || "Name field cannot be empty"),
					emailError: this.state.email ? "" : (this.state.emailError || "Email field cannot be empty"),
					messageError: this.state.message ? "" : (this.state.messageError || "Message field cannot be empty"),
					subjectError: this.state.subject ? "" : (this.state.subjectError || "Subject field cannot be empty"),
					recaptchaTokenError: this.state.recaptchaToken ? "" : (this.state.recaptchaTokenError || "Please complete captcha challenge"),
				});
			}

			Request
				.post("/api/contact-form", {
					name: this.state.name,
					email: this.state.email,
					subject: this.state.subject,
					message: this.state .message,
					recaptchaToken: this.state.recaptchaToken
				}).then(r => {
						this.setState({postMessage: r.message, postStatus: r.status });
			}).catch((err) => {
						this.setState({postMessage: "Error making request Please try again", postStatus: "500" });
			})

	};

	captchaChange = async (res:any) => {
		this.setState({recaptchaToken: res, recaptchaTokenError: ""});
		return false;
	};

	captchaExpired = async (e:any) => {
		this.setState({recaptchaToken: "", recaptchaTokenError: "Please complete captcha challenge"});
		return false;
	};

	public render():
		React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | React.ReactPortal | boolean | null | undefined {
		return (
			<div className="contact">
				<div className="flex justify-center items-center">
					<div
						className="flex flex-col justify-start items-start w-full gap-6 p-8 md:p-16 rounded-[45px] bg-gray-100 dark:bg-gray-800">
						<div className="flex flex-col w-full justify-start items-start gap-6">
							<div className="flex flex-col justify-start items-start gap-2.5 w-full">
								<label className="text-base text-left text-black dark:!text-white">{i18next.t("Name")} <sup className="text-red-500">*</sup></label>
								<input
									onChange={this.change("name")}
									placeholder={i18next.t("Name")}
									type="text"
									className="w-full p-4 rounded-lg bg-white dark:bg-gray-700 border border-black dark:border-gray-600 text-lg text-black dark:!text-white"
								/>
								{this.state.nameError && <span className="text-red-500">{i18next.t(this.state.nameError)}</span>}
							</div>
							<div className="flex flex-col justify-start items-start gap-2.5 w-full">
								<label className="text-base text-left text-black dark:!text-white">{i18next.t("Email")}<sup className="text-red-500">*</sup></label>
								<input
									onChange={this.change("email")}
									placeholder={i18next.t("Email")}
									type="email"
									className="w-full p-4 rounded-lg bg-white dark:bg-gray-700 border border-black dark:border-gray-600 text-lg text-black dark:!text-white"
								/>
								{this.state.emailError && <span className="text-red-500">{i18next.t(this.state.emailError)}</span>}
							</div>
							<div className="flex flex-col justify-start items-start gap-2.5 w-full">
								<label className="text-base text-left text-black dark:!text-white">{i18next.t("Subject")}<sup className="text-red-500">*</sup></label>
								<input
									onChange={this.change("subject")}
									placeholder={i18next.t("Subject")}
									type="text"
									className="w-full p-4 rounded-lg bg-white dark:bg-gray-700 border border-black dark:border-gray-600 text-lg text-black dark:!text-white"
								/>
								{this.state.subjectError && <span className="text-red-500">{i18next.t(this.state.subjectError)}</span>}
							</div>
							<div className="flex flex-col justify-start items-start gap-2.5 w-full">
								<label className="text-base text-left text-black dark:!text-white">{i18next.t("Message")}<sup className="text-red-500">*</sup></label>
								<textarea
									onChange={this.change("message")}
									placeholder={i18next.t("Message")}
									className="w-full h-48 p-4 rounded-lg bg-white dark:bg-gray-700 border border-black dark:border-gray-600 text-lg text-black dark:!text-white"
								/>
								{this.state.messageError && <span className="text-red-500">{i18next.t(this.state.messageError)}</span>}
							</div>
						</div>
						<div className="flex flex-col mt-6">
							<ReCAPTCHA
								className="g-recaptcha"
								sitekey="6LcxQjgpAAAAAKe7EBOCgO0KBirP2umkQqBi1B-i"
								onChange={this.captchaChange}
								onExpired={this.captchaExpired}
								onError={this.captchaExpired}
							/>
							{this.state.recaptchaTokenError && (
								<section className="text-left text-red-500">{i18next.t(this.state.recaptchaTokenError)}</section>
							)}
						</div>
						<div className="flex flex-col w-full justify-center items-center mt-6">
							<button
								onClick={this.submitForm}
								className="px-8 py-4 w-full text-center rounded-lg text-white bg-clip text-xl"
							>
								{i18next.t("Send Message")}
							</button>
							{this.state.postMessage && (
								<div className={`${["200", "201"].includes(this.state.postStatus.toString()) ? "text-green-500" : "text-red-500"} mt-4`}>
									{i18next.t(this.state.postMessage)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

